.maps-autocomplete {
  position: absolute;
  top: 4.583%;
  right: 8.33%;
  z-index: 1;
  width: 83.33%;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.info-window-inner h3,
.info-window-inner p {
  padding-left: 16px;
  padding-right: 16px;
}

.info-window-inner h3 {
  font-size: 18px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: bold !important;
  margin-top: 10px !important;
  margin-bottom: 8px !important;
  color: #000000 !important;
}

.info-window-inner p {
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  color: #000000 !important;
  margin-bottom: 16px !important;
}

.info-window-main .MuiButtonBase-root span {
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-family: "Poppins", sans-serif !important;
  color: #f6c600 !important;
}

.gm-style .gm-style-iw-c {
  max-width: 324.5px !important;
  max-height: 156px !important;
  width: 100% !important;
  height: 100vh !important;
  border-radius: 12px !important;
}

.gm-style-iw {
  width: 300px;
  height: 400px;
  box-shadow: none !important;
}

.gm-style-iw::before {
  display: none !important;
}

.gm-style .gm-style-iw-tc::after {
  display: none !important;
}

.gm-style-iw {
  padding-left: 0 !important;
}

.gm-style-iw-d {
  overflow: auto !important;
  padding-right: 0 !important;
}

.gm-ui-hover-effect {
  position: absolute !important;
  top: -1px !important;
  right: -1px !important;
  // background: url("../../assets/images/Malayali/Third-Section/map-cross.svg") !important;
  // background-repeat: no-repeat !important;
  // background-position: center !important;
  // background-size: cover !important;
}
