.invoice-field-checkbox {
  width: 24px;
  height: 24px;
}

.capitalize {
  text-transform: capitalize;
}

.MuiButtonBase-root.manage-fields-button {
  background: url(../../assets/images/Invoices/manage-fields-icon.svg);
  background-repeat: no-repeat;
  color: #09414e;
  height: 1.3021vw;
  min-width: 1.3021vw;
  padding-right: 0;
  width: 1.3021vw;
}
