.maps-autocomplete-content h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-bottom: 0;
}

.maps-autocomplete-content
  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  padding-right: 10px;
}

.maps-autocomplete-content
  .MuiAutocomplete-root
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border: none;
}

.maps-autocomplete-content
  .MuiAutocomplete-root
  .MuiInputBase-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #a5a59f;
}

.MuiAutocomplete-popupIndicator {
  display: none;
}

@media (max-width: 767px) {
  .maps-autocomplete-content h2 {
    font-size: 24px;
  }

  .maps-autocomplete-content
    .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
    padding-top: 0;
    padding-bottom: 0;
  }
}
