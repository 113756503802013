.city-name {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #5a5b5b;
}

.quick-action-modal {
  width: 148px;
  height: 105px;
  top: 90%;
  right: 45%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.item-status-card {
  margin-bottom: 8px;
  cursor: pointer;
}

.item-status-card:hover {
  background-color: #f5f5f5;
}

.restaurant-status-card:hover {
  background-color: #f5f5f5;
}

.fav-card-approved-info:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
