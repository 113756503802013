body {
  background-color: #fafafa;
}

.master-layout-container {
  width: 100%;
  margin: auto;
  min-width: 98.89322vw;
  overflow-x: hidden;
}

.half-width {
  margin: auto;
  padding-left: 16.46%;
  padding-right: 0;
  padding-top: 60px;
}

.full-width {
  padding-top: 60px;
  padding-left: 2.6%;
  padding-right: 0;
}

/* Media queries */

@media screen and (max-width: 992px) {
  .half-width {
    padding-left: 12%;
  }
}
