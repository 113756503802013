.invoices-table thead tr th {
  font-size: 14px;
  font-weight: 500;
}
.table tr > td {
  padding: 0;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0 !important;
}

.MuiPaper-root.favourited-items-card h6,
.MuiPaper-root.favourited-items-card h5,
.MuiPaper-root.favourited-items-card p {
  margin-bottom: 0;
}

.MuiPaper-root.favourited-items-card h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.fav-card-count {
  margin-bottom: 12px;
}

.MuiPaper-root.favourited-items-card .fav-card-count h6 {
  font-weight: bold;
  color: #003a48;
}

.MuiPaper-root.favourited-items-card h5 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.MuiPaper-root.favourited-items-card .fav-card-item-info h6 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #666666;
}

.fav-card-dish-address p {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #3b3b3b;
}

.fav-card-dish-address h6 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #666666;
}

.MuiPaper-root.favourited-items-card .fav-card-approved-info h6 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.MuiPaper-root.favourited-items-card .fav-card-approved-info span {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.activity-log-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.content-title-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-accent-bg: #dfebee;
  color: var(--bs-table-striped-color);
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #fff;
  color: var(--bs-table-striped-color);
}

.thead-main {
  background-color: #dfebee;
  border-top: 1px solid #000000;
}

.invoices-table tbody tr {
  vertical-align: middle;
}
.invoices-table tbody tr:hover {
  background-color: #fac8b2;
  cursor: pointer;
}

th.table-headings {
  text-transform: capitalize;
  white-space: nowrap;
}

.invoices-table tbody td.td-small {
  font-size: 12px;
  font-weight: 400;
  color: #222222;
}

.invoices-table tbody td.td-name {
  color: #2d9dcb;
  font-size: 12px;
  font-weight: 500;
}

.invoices-table tbody td.td-status {
  font-size: 12px;
  font-weight: 600;
}

.action-btn {
  margin-right: 13px;
}

.invoices-table-container {
  margin-bottom: 30px;
}

.invoices-table-container tbody tr td {
  font-size: 0.78125vw;
  color: #222222;
  padding: 0px 8px;
}
// .input-image{
//   opacity: 0;
// }
/* Media-queries */

.approve-reject-container {
  display: flex;
  gap: 1rem;
}

.approve-reject-container .MuiButtonBase-root {
  padding: 0.25rem 1.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.75;
  background-color: #ffffff;
  color: #09414e;
  border: 1px solid #09414e;
  border-radius: 0.625rem;
  text-transform: capitalize;
  box-shadow: none;
}

.approve-reject-container .MuiButtonBase-root:hover {
  background-color: #09414e;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .invoices-table-container {
    width: 100%;
    overflow-x: auto;
  }

  .invoices-table-container tbody tr td {
    font-size: 14px;
    color: #222222;
    padding: 0px 8px;
  }
}
