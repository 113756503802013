.header-image img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  outline: 4px solid #fff;
}

.profile-info {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
