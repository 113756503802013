.dashboard-heading {
  padding-bottom: 12px;
  border-bottom: 1px solid #ebeef1;
}

.dashboard-heading h3 {
  font-size: 28px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.today-block {
  width: 200px;
  height: 88px;
  padding: 16px 93px 16px 16px;
}

.total-block {
  width: 200px;
  height: 88px;
  padding: 18px 28px 27px 28px;
  border-top: 1px solid #ebeef1;
  border-right: 1px solid #ebeef1;
}

.total-block p {
  font-family: "Roboto", sans-serif;
  color: #687385;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 0;
}

p.total-block-text {
  font-family: "Roboto", sans-serif;
  color: #697386;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  margin-bottom: 0;
}

.today-block p {
  font-family: "Roboto", sans-serif;
  color: #687385;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 0;
}

.customize-span {
  font-family: "Roboto", sans-serif;
  color: #324c57;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.dashboard-cards-row {
  width: 692px;
  margin-bottom: 48px;
}

.pending-card-head {
  background-color: #f6f8fa;
  border-bottom: 1px solid #d5dbe1;
  padding: 8px 16px;
}

.pending-card-head img {
  margin-right: 8px;
}

.pending-card-head p {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #30313d;
  margin-bottom: 0;
}

.pending-sub-card {
  padding: 16px;
  border-bottom: 1px solid #d5dbe1;
}

.pending-sub-card h4 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #30313d;
  margin-bottom: 4px;
}

.pending-sub-card h6 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #6a7383;
  margin-bottom: 8px;
}

.pending-sub-card p {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #005d72;
  margin-bottom: 0;
}

span.viewall-btn-dash {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.MuiPaper-root.dashboard-common-card {
  box-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.25);
  width: 324px;

  border-radius: 16px;
}

.MuiPaper-root.dashboard-common-card.dashboard-first-card,
.MuiPaper-root.dashboard-common-card.dashboard-second-card {
  background-color: #f9faff;
}

.MuiPaper-root.dashboard-common-card.dashboard-third-card,
.MuiPaper-root.dashboard-common-card.dashboard-fourth-card,
.MuiPaper-root.dashboard-common-card.dashboard-fifth-card,
.MuiPaper-root.dashboard-common-card.dashboard-sixth-card,
.dashboard-task-section .MuiPaper-root {
  background-color: #f9feff;
}

.MuiPaper-root.dashboard-common-card .MuiCardContent-root {
  padding: 20px 17px 33px 18px;
}

.MuiPaper-root.dashboard-common-card p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
}

.MuiButtonBase-root.dashboard-dropdown-btn {
  padding: 0;
}

.dashboard-card-section {
  margin-bottom: 19px;
}

.custom-padding {
  padding-top: 13px;
}

.dashboard-task-section {
  width: 39.157%;
  background-color: #f9feff;
  margin-top: 35.2px;
}

.dashboard-task-section .MuiPaper-root {
  box-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.25);
  border-radius: 16px;
}

.pending-tasks-icon {
  width: 24px;
  height: 24px;
  border-radius: 25px;
  background-color: red;
  color: #ffffff;
}

.dashboard-task-heading {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.dashboard-task-heading h6 {
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.dashboard-task-heading p {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: #9d2066;
  margin-bottom: 0;
}

.dashboard-task-category .MuiPaper-root {
  background-color: #eaeaea;
  box-shadow: none;
  height: 55px;
}

.dashboard-task-category .MuiPaper-root .MuiCardContent-root {
  padding: 10px;
}

.dashboard-task-category p {
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.task-info-icon {
  margin-right: 10px;
}

.MuiButtonBase-root.task-view-btn {
  background-color: #ffffff;
  border: 1px solid #09414e;
  color: #09414e;
  border-radius: 10px;
  text-transform: capitalize;
  min-width: 85px;
  height: 35px;
}

.MuiButtonBase-root.task-view-btn span {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: normal;
}
