.breadcrumb-main {
  margin-left: 20px !important;
  padding-top: 20px;
  padding-bottom: 18px;
}

.breadcrumb-main a,
.breadcrumb-main span {
  font-family: "Inter", sans-serif;
  font-size: 0.9115vw;
  font-weight: 600;
  text-decoration: none;
}

.breadcrumb-main *:nth-child(n + 2) {
  color: #09414e;
}

.breadcrumb-main .first-breadcrumb {
  color: #BD6844;
}

nav.bread-spacing {
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 18px;
}
.content {
  margin-top: 5px;
}
