.item-details-main-cntnr{
    border: 1px solid #BFBFBF;
    padding-left: 8px;
    margin-bottom: 15px;
}

.upload-img{
    width: 150px;
    color: #9D2066;
}

.delete-img{
    padding-left: 10px;
    color: #9D2066; 
}

.scroll-row{
    width: 100%;
    overflow-x: auto;
    column-gap: 20px;
    display: flex;
  flex-wrap: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.scroll-row::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

.col-box{
    background: #FFFFFF;
    /* greyish */
  
    border: 1px solid #7CA5B0;
    
}

.col-card-image{
    display: flex;
    justify-content: center;
    align-items: center;
}



.carousel {
    position: relative;
  }
  .carousel-item img {
    object-fit: cover;
  }
  #carousel-thumbs {
    background: rgba(255,255,255,.3);
    bottom: 0;
    left: 0;
    padding: 0 50px;
    right: 0;
  }
  #carousel-thumbs img {
    border: 5px solid transparent;
    cursor: pointer;
  }
  #carousel-thumbs img:hover {
    border-color: rgba(255,255,255,.3);
  }
  #carousel-thumbs .selected img {
    border-color: #fff;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 50px;
  }
  @media all and (max-width: 767px) {
    .carousel-container #carousel-thumbs img {
      border-width: 3px;
    }
  }
  @media all and (min-width: 576px) {
    .carousel-container #carousel-thumbs {
      position: absolute;
    }
  }
  @media all and (max-width: 576px) {
    .carousel-container #carousel-thumbs {
      background: #ccccce;
    }
  }