.tabswitch-container {
  background-color: #fff;
  // margin-left: 20px;
  // margin-right: 20px;
}


.tabswitch-head button{
  color: #313131;
}
.tabswitch-head button,
.tabswitch-head button.Mui-selected {
  text-transform: capitalize;
}

.tabswitch-head button.Mui-selected {
  color: #BD6844 !important;
}

.tabswitch-container {
  font-family: "Inter", sans-serif;
}

/*Media queries */

@media screen and (max-width: 992px) {
  div.tabswitch-container {
    width: auto;
  }
}
