.sidebar {
  margin: 0;
  padding: 0;
  width: 16.46%;
  background-color: #dce8eb;
  position: fixed;
  box-shadow: var(
    --shadow-panel-v9vx7i,
    0 1px 1px 0 rgba(0, 28, 36, 0.3),
    1px 1px 1px 0 rgba(0, 28, 36, 0.15),
    -1px 1px 1px 0 rgba(0, 28, 36, 0.15)
  );
  top: 60px;
  height: calc(100% - 90px);
  overflow: auto;
  z-index: 1;
  transition: width 0.15s;
}

.sidebar_collapsed {
  width: 40px !important;
  transition: width 0.15s;
}

.sidenav_collapsed_head {
  font-size: 15px;
  padding: 13px !important;
}

.fabars-color {
  color: #09414e;
}

.sidebar a:nth-child(3) {
  padding-top: 6px !important;
}

.sidebar a {
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.sidenav-sub-item {
  padding-left: 27px;
}

a.sidenav-dashboard-item {
  margin-top: 15px;
  margin-bottom: 9px;
}

.sidenav-item-spacing,
a.sidenav-item-spacing {
  margin-bottom: 17px;
}

.sidenav-sub-heading {
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 0.9115vw;
  font-weight: 700;
  line-height: 16.94px;
  padding-left: 13px;
  color: #353535;
  padding-top: 6px;
  padding-bottom: 6px;
  display: block;
  text-decoration: none;
}

.sidenav-sub-heading img {
  margin-right: 4px;
}

.sidebar-dropdown-items a {
  padding-left: 25px;
}

.sidebar a.sidenav_head:hover:not(.active),
.sidebar a.sidenav_collapsed_head:hover:not(.active) {
  background-color: transparent;
}

.sidebar_cross {
  position: absolute;
  top: 22px;
  right: 18px;
  font-size: 20px;
  height: 20px;
  cursor: pointer;
}

.sidenav_head {
  padding: 20px !important;
  padding-left: 24px !important;
  border-bottom: 1px solid #eaeded;
}

.sidenav_head_title {
  color: #09414e;
  font-size: 1.042vw;
  font-weight: 700;
}

/* Sidebar links */
.sidebar a {
  display: block;
  color: black;
  // padding: 16px;
  text-decoration: none;
}

/* Active/current link */
.sidebar a.active {
  color: black;
  background-color: #e5eef0;
}

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
  color: black;
  background-color: #e5eef0;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

.trip-expenses-heading {
  padding-left: 3px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar a {
    float: left;
  }

  div.content {
    margin-left: 0;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }

  .sidenav-sub-heading {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 16.94px;
    padding-left: 13px;
    color: #353535;
    padding-top: 6px;
    padding-bottom: 6px;
    display: block;
    text-decoration: none;
  }

  .sidebar a {
    text-align: left;
  }

  .sidenav_head_title {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sidebar a {
    text-align: center;
    float: none;
  }

  .sidenav-sub-heading {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 16.94px;
    padding-left: 13px;
    color: #353535;
    padding-top: 6px;
    padding-bottom: 6px;
    display: block;
    text-decoration: none;
  }

  .sidebar a {
    text-align: left;
  }

  .sidenav_head_title {
    font-size: 20px;
  }
}
